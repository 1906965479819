import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';

import { removeSearchParams } from './location-helper';

const ENV_STORAGE_KEY = 'acheev-web-env';
const ENV_SEARCH_PARAM_KEY = 'env';
const LOCAL_ENV_SEARCH_PARAM_VALUE = 'local';
const LOCAL_ENV_PUBLIC_PATH = 'https://localhost:8080/';

enum AppEnvKeys {
  Name = 'name',
  PublicPath = 'publicPath',
  IsDefault = 'isDefault',
}

interface AppEnv {
  [AppEnvKeys.IsDefault]: boolean;
  [AppEnvKeys.Name]: string;
  [AppEnvKeys.PublicPath]: string;
}

const appEnvDefaults: AppEnv = {
  [AppEnvKeys.PublicPath]: `${App.staticPath}`,
  [AppEnvKeys.Name]: '',
  [AppEnvKeys.IsDefault]: true,
};

const getEnvFromSearchParams = (): AppEnv | {} => {
  const parsedParams = queryString.parse(window.location.search);
  const envSearchParamValue = parsedParams[ENV_SEARCH_PARAM_KEY];

  return {
    ...(envSearchParamValue === LOCAL_ENV_SEARCH_PARAM_VALUE
      ? {
          [AppEnvKeys.PublicPath]: LOCAL_ENV_PUBLIC_PATH,
          [AppEnvKeys.Name]: 'local',
        }
      : {}),
  };
};

const getEnvFromSession = (): AppEnv | {} => {
  const sessionEnv = window.sessionStorage.getItem(ENV_STORAGE_KEY);

  return sessionEnv ? JSON.parse(sessionEnv) : {};
};

export const getAppEnv = () => {
  const sessionEnv = getEnvFromSession();
  const paramsEnv = getEnvFromSearchParams();

  const appEnv: AppEnv = {
    ...appEnvDefaults,
    ...sessionEnv,
    ...paramsEnv,
  };

  // shallow diff vs default
  const resultIsDefault = !(Object.keys(appEnv) as Array<AppEnvKeys>).some(key => {
    return appEnv[key] !== appEnvDefaults[key];
  });

  const result = {
    ...appEnv,
    [AppEnvKeys.IsDefault]: resultIsDefault,
  };

  if (!resultIsDefault) {
    window.sessionStorage.setItem(ENV_STORAGE_KEY, JSON.stringify(result));
  }

  return result;
};

export const clearAppEnv = ({ history }: { history: RouteComponentProps['history'] }) => {
  window.sessionStorage.removeItem(ENV_STORAGE_KEY);
  removeSearchParams({
    paramKeys: [ENV_SEARCH_PARAM_KEY],
    history,
  });
  window.location.reload();
};
