import './public-path';
import React from 'react';
import ReactDOM from 'react-dom';

window.Acheev = {
  ...(window.Acheev || {}),
  renderLoginPage: (root: Element) => {
    import('./site/pages/login-page').then(({ default: LoginPage }) => {
      ReactDOM.render(<LoginPage />, root);
    });
  },
  renderPurchaseOrderAcceptancePage: (root: Element) => {
    import('./site/pages/purchase-order-acceptance-page').then(
      ({ default: PurchaseOrderAcceptance }) => {
        ReactDOM.render(<PurchaseOrderAcceptance />, root);
      },
    );
  },
};
